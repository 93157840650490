<template>
  <span @click.stop="showInfo(STORE, DATA, NODE)" class="tree-expand">
    <span class="tree-label" v-show="DATA.isEdit">
      <el-input
        class="edit"
        size="mini"
        autofocus
        v-model="DATA.name"
        :ref="'treeInput' + DATA.id"
        @click.stop.native="nodeEditFocus"
        @keyup.enter.stop.native="nodeEditPass(STORE, DATA, NODE)"
        @blur.stop="nodeEditPass(STORE, DATA, NODE)"
      ></el-input>
    </span>
    <span class="tipArea2">
      <div v-if="DATA.submitStatus == 2" class="icon1"></div>
      <span v-if="DATA.submitStatus == 2" class="text">(已代填)</span>
      <div v-if="DATA.submitStatus == 1" class="icon2"></div>
      <span v-if="DATA.submitStatus == 1" class="text">(未填写)</span>
      <div v-if="DATA.submitStatus == 3" class="icon3"></div>
      <span v-if="DATA.submitStatus == 3" class="text">(已提交)</span>
    </span>
    <span
      v-show="!DATA.isEdit"
      :class="[DATA.id == currentId ? 'tree-new tree-label' : 'tree-label']"
    >
      <!-- <span>{{DATA.name|fnName}}</span> -->
      <span>{{ fnName }}</span>
    </span>
    <span class="tree-btn" v-show="!DATA.isEdit">
      <!-- <i v-if="isAuth('org-users-dept-add')" class="el-icon-plus" @click.stop="nodeAdd(STORE,DATA,NODE)"></i>
			<i v-if="isAuth('org-users-dept-edit')" class="el-icon-edit" @click.stop="nodeEdit(STORE,DATA,NODE)"></i>
			<i v-if="isAuth('org-users-dept-delete') && NODE.level !== 1" class="el-icon-delete" @click.stop="nodeDel(STORE,DATA,NODE)"></i> -->
      <!-- 		<i class="el-icon-plus" @click.stop="nodeAdd(STORE,DATA,NODE)"></i> -->
      <img
        class="edit-btn"
        src="~@/assets/img/edit-btn.png"
        v-if="NODE.level == 1"
        @click.stop="nodeEdit(STORE, DATA, NODE)"
        alt=""
      />
      <img
        src="~@/assets/img/delete-btn.png"
        v-if="NODE.level == 1"
        @click.stop="nodeDel(STORE, DATA, NODE)"
        alt=""
      />
      <img
        src="../../../../assets/icon/iocn-20.png"
        v-if="NODE.level == 2 && DATA.relationId"
        style="width:15px;height: 14px;"
        alt=""
      />
      <el-popover
        placement="top-start"
        trigger="hover"
        content="发送问卷"
        class="popover-custom"
      >
        <img
          slot="reference"
          src="../../../../assets/icon/icon-19.png"
          v-if="
            NODE.level == 2 &&
            !DATA.relationId &&
            DATA.submitStatus !== 2 &&
            DATA.infoId
          "
          @click.stop="nodeSend(STORE, DATA, NODE)"
          alt=""
        />
      </el-popover>

      <!-- <img src="../../../../assets/icon/icon-20.png" style="width: 14px;height: 14px;position: absolute;right: 25px;"   v-if="NODE.level == 2&&DATA.relationId" @click.stop="nodeDel(STORE,DATA,NODE)" alt=""> -->
      <!-- <i  v-if="NODE.level !== 1" class="el-icon-edit" @click.stop="nodeEdit(STORE,DATA,NODE)"></i>
			<i v-if="NODE.level !== 1"  class="el-icon-delete" @click.stop="nodeDel(STORE,DATA,NODE)"></i> -->
      <!-- <img src="../../../../assets/icon/icon-19.png" style="width: 14px;height: 14px;position: absolute;right: 25px;"   @click.stop="nodeEdit(STORE,DATA,NODE)" alt=""> -->
      <!-- <img src="../../../../assets/icon/iocn-20.png" style="width: 14px;height: 14px;position: absolute;right: 25px;"   @click.stop="nodeEdit(STORE,DATA,NODE)" alt=""> -->
    </span>
  </span>
</template>

<script>
export default {
  name: "treeExpand",
  props: ["NODE", "DATA", "STORE", "maxexpandId", "currentId"],
  methods: {
    init() {
      console.log(data, clickId);
    },
    nodeAdd(s, d, n) {
      //新增
      // console.log(s,d,n,'aaaaaa');
      this.$emit("nodeAdd", s, d, n);
      console.log(n.level);
    },
    showInfo(s, d, n) {
      //编辑
      this.$emit("nodeShow", s, d, n);
    },
    nodeEdit(s, d, n) {
      //编辑
      console.log("nodeEdit==========", d);
      this.$emit("nodeEdit", s, d, n);
      // d.isEdit = true;
      // this.$nextTick(() => {
      // 	this.$refs['treeInput'+d.id].$refs.input.focus()
      // })
      // this.$forceUpdate()
    },
    nodeDel(s, d, n) {
      //删除
      this.$emit("nodeDel", s, d, n);
    },
    nodeSend(s, d, n) {
      //发送
      this.$emit("nodeSend", s, d, n);
    },
    nodeEditPass(s, d, n) {
      //编辑完成
      console.log(this.DATA.name, "down");
      if (d.isEdit) {
        d.isEdit = false;
        this.$emit("nodeEdit", s, d, n);
      }
    },
    nodeEditFocus() {
      //阻止点击节点的事件冒泡
    },
  },
  computed: {
    fnName() {
      if (!this.DATA.name) {
        return "";
      } else if (this.DATA.name.length > 16) {
        return this.DATA.name.slice(0, 13) + "...";
      } else {
        return this.DATA.name;
      }
    },
  },
  mounted() {
    console.log(this.DATA);
  },
};
</script>

<style lang="scss">
.tipArea2 {
  .icon1 {
    display: inline-block;
    border-radius: 50px;
    width: 6px;
    height: 6px;
    background: #009cff;
    margin-right: 6px;
  }

  // .icon2{
  // 	font-size: 32px;
  // 	color: #F61111;

  // }

  .icon2 {
    display: inline-block;
    border-radius: 50px;
    width: 6px;
    height: 6px;
    background: #f61111;
    margin-right: 6px;
  }

  .icon3 {
    display: inline-block;
    border-radius: 50px;
    width: 6px;
    height: 6px;
    background: #10cd30;
    margin-right: 6px;
  }

  .text {
    margin-right: 10px;
    height: 17px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 400;
    color: #999999;
    line-height: 17px;
  }
}
.tree-expand {
  overflow: hidden;
  display: inline-block;
  width: 100%;
}
.tree-expand .tree-new {
  font-weight: 600;
  /* font-weight: normal; */
}
.tree-expand .tree-label {
  font-size: 0.9em;
}
.tree-expand .tree-label .edit {
  width: 80%;
}
.tree-expand .tree-btn {
  display: none;
  float: right;
  margin-right: 20px;
  img {
    width: 14px !important;
    height: 14px !important;
    &.edit-btn {
      margin-right: 10px;
    }
  }
}
.tooltip {
  position: absolute;
  right: 20px;
  z-index: 10000;
}
.popover-custom{
  position: absolute;
  z-index: 10;
}
.tree-expand .tree-btn i {
  color: #8492a6;
  font-size: 0.9em;
  margin-right: 3px;
}
</style>
