<template>
  <div class="expand" style="height: 300px !important;">
    <div>
      <el-tree
        show-checkbox
        ref="expandMenuList"
        class="expand-tree"
        v-if="isLoadingTree"
        :data="setTree"
        node-key="id"
        highlight-current
        :check-on-click-node="true"
        :props="defaultProps"
        :expand-on-click-node="false"
        :render-content="renderContent"
        :accordion="true"
        :default-expanded-keys="defaultExpandKeys"
        @check="handleNodeClick"
      ></el-tree>
    </div>
  </div>
</template>
<!-- VUE饿了么树形控件添加增删改功能按钮 -->
<script>
import Qs from "qs";
import TreeRender from "./tree_render";
import api from "@/resource/api";
import { treeDataTranslate } from "@/utils";
export default {
  name: "tree",
  props: ["type", "content", "defaultList"],
  data() {
    return {
      //         maxexpandId: api.maxexpandId,//新增节点开始id
      //         non_maxexpandId: api.maxexpandId,//新增节点开始id(不更改)
      maxexpandId: "", //新增节点开始id
      non_maxexpandId: "", //新增节点开始id(不更改)
      num: "", //排序
      isLoadingTree: true, //是否加载节点树
      setTree: [], //节点树数据
      clientHeight:
        `${document.documentElement.clientHeight}` - 50 - 30 - 2 - 80,
      defaultProps: {
        children: "children",
        label: "name",
      },

      defaultExpandKeys: [], //默认展开节点列表
      currentId: "", //当前点击树id
      prescription: {
        name: "处方信息",
        value: "prescription",
        children: [
          { name: "西药/中成药处方", value: 2, id: 2 },
          { name: "中药处方", value: 1, id: 1 },
        ],
      },
    };
  },
  created() {
    // console.log(this.type,'created tree');
  },
  mounted() {
    // console.log(this.type,'mounted tree');
    console.log(this.$attrs, "attrs");
    //this.type = JSON.parse(JSON.stringify(this.$attrs.type))

    this.$forceUpdate();
    // this.initExpand();
    this.getDeptList();
  },
  activated() {},
  watch: {
    type(value) {
      console.log(value, "watch value");
    },
  },
  methods: {
    initExpand() {
      this.isLoadingTree = true;
      if (this.defaultList && this.defaultList.length) {
        this.defaultList.forEach(ele=>{
          if (ele.prescriptionType == 1) {
            this.prescription.children[1].infoId = ele.infoId;
          }
          if (ele.prescriptionType == 2) {
            this.prescription.children[0].infoId = ele.infoId;
          }
        })
        this.defaultExpandKeys = this.defaultList.map(item=>{
          return item.id
        });
        this.$refs.expandMenuList.setCheckedKeys(this.defaultExpandKeys);
      }
      console.log("this.defaultExpandKeys", this.defaultExpandKeys);
    },
    // 获取部门列表
    getDeptList(data) {
      this.$forceUpdate();
      console.log(this.$attrs.type);
      console.log(this.type);
      let type = this.type;
      // let type = sessionStorage.getItem('type')
      // console.log(type,'type');
      // console.log(data,'dataaaaa');
      // let user = JSON.parse(sessionStorage.getItem("userInfo"))
      // 				let obj = {
      // 					deptId: user.deptId,
      // 					'userId':this.$cookie.get('userId'),
      // 				}
      let dict = "";
      let url = "";
      let userInfo = "";
      if (localStorage.userInfo) {
        userInfo = JSON.parse(localStorage.userInfo);
      }
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      if (type == 4) {
        dict = "宣教分类";
        url = "/dict/getMissionaryWorkAndSysDicts?type=" + dict;
        let teamsId = "";
        if (localStorage.currentTeam) {
          teamsId = JSON.parse(localStorage.currentTeam).id;
        }

        this.$httpAes({
          url: this.$httpAes.adornUrl(url),
          method: "post",
          data: {
			  stringParam1:dict,
            stringParam2:teamsId,
            stringParam3: this.content,
            pageSize: 999,
            pageNo: 1,
          },
        }).then(({ data }) => {
          console.log(data.data, "dddd");
          for (var i = 0; i < data.data.length; i++) {
            data.data[i].isEdit = false;
            data.data[i].children = data.data[i].map.list;
            data.data[i].map.missionaryWorks.forEach((item) => {
              item.name = item.workName;
            });
            data.data[i].children = [
              ...data.data[i].map.list,
              ...data.data[i].map.missionaryWorks,
            ];
            for (var k = 0; k < data.data[i].children.length; k++) {
              if (data.data[i].children[k].map) {
                data.data[i].children[k].map.missionaryWorks.forEach((item) => {
                  item.name = item.workName;
                });
                data.data[i].children[k].children = [
                  ...data.data[i].children[k].map.list,
                  ...data.data[i].children[k].map.missionaryWorks,
                ];
                for (
                  var j = 0;
                  j < data.data[i].children[k].children.length;
                  j++
                ) {
                  if (data.data[i].children[k].children[j].map) {
                    data.data[i].children[k].children[j].children =
                      data.data[i].children[k].children[j].map.list;

                    for (
                      var m = 0;
                      m < data.data[i].children[k].children[j].children.length;
                      m++
                    ) {
                      if (
                        data.data[i].children[k].children[j].children[m].map
                      ) {
                        data.data[i].children[k].children[j].children[
                          m
                        ].children =
                          data.data[i].children[k].children[j].children[
                            m
                          ].map.list;
                      }
                    }
                  }
                }
              }
            }
          }
          // 					for (var i = 0; i < data.data.length; i++) {
          // 						data.data[i].isEdit = false;
          // 						data.data[i].children = data.data[i].map.list;

          // 						for(var k=0;k<data.data[i].children.length;k++){
          // 							if(data.data[i].children[k].map){
          // 								data.data[i].children[k].children =data.data[i].children[k].map.list
          // 							for(var j=0;j<data.data[i].children[k].children.length;j++){
          // 								if(data.data[i].children[k].children[j].map){
          // 									data.data[i].children[k].children[j].children =data.data[i].children[k].children[j].map.list
          // 								for(var m=0;m<data.data[i].children[k].children[j].children.length;m++){
          // 									if(data.data[i].children[k].children[j].children[m].map){
          // 										data.data[i].children[k].children[j].children[m].children =data.data[i].children[k].children[j].children[m].map.list

          // 									}

          // 								}
          // 								}

          // 							}
          // 							}
          // 						}
          // 					}

          let tree = treeDataTranslate(data.data);
          tree.push(this.prescription);
          console.log(tree, "tree");
          this.setTree = tree;
          this.maxexpandId = data.data[data.data.length - 1].id;
          this.non_maxexpandId = data.data[data.data.length - 1].id;
          this.num = data.data[data.data.length - 1].num;
        });
      } else {
        // url="/vfTableInfo/list?hospitalId="+userInfo.deptPid
        dict = "表单类别";
        url = "/dict/getVfTablesAndSysDicts?type=" + dict;
        this.$httpAes({
          url: this.$httpAes.adornUrl(url),
          method: "post",
          data: {
            // hospitalId:this.$cookie.get('hospitalId'),
           stringParam2:teamsId,
           
           stringParam3:this.content,
            pageSize: 999,
            pageNo: 1,
          },
          // data: Qs.stringify(obj)
        }).then(({ data }) => {
          // if(user.deptId!=null){
          // 	let obj = {
          // 		id:user.deptId,
          // 		fullName:user.deptName,
          // 		simpleName:user.deptName,
          // 		num:'1',
          // 		pids:''
          // 	}
          // 	data.data.unshift(obj)
          // }
          console.log(data.data, "dddd");
          for (var i = 0; i < data.data.length; i++) {
            data.data[i].isEdit = false;
            data.data[i].children = data.data[i].map.list;
            data.data[i].map.vfTableInfo.forEach((item) => {
              item.name = item.tableName;
            });
            data.data[i].children = [
              ...data.data[i].map.list,
              ...data.data[i].map.vfTableInfo,
            ];
            for (var k = 0; k < data.data[i].children.length; k++) {
              if (data.data[i].children[k].map) {
                data.data[i].children[k].map.vfTableInfo.forEach((item) => {
                  item.name = item.tableName;
                });
                data.data[i].children[k].children = [
                  ...data.data[i].children[k].map.list,
                  ...data.data[i].children[k].map.vfTableInfo,
                ];
                for (
                  var j = 0;
                  j < data.data[i].children[k].children.length;
                  j++
                ) {
                  if (data.data[i].children[k].children[j].map) {
                    data.data[i].children[k].children[j].children =
                      data.data[i].children[k].children[j].map.list;

                    for (
                      var m = 0;
                      m < data.data[i].children[k].children[j].children.length;
                      m++
                    ) {
                      if (
                        data.data[i].children[k].children[j].children[m].map
                      ) {
                        data.data[i].children[k].children[j].children[
                          m
                        ].children =
                          data.data[i].children[k].children[j].children[
                            m
                          ].map.list;
                      }
                    }
                  }
                }
              }
            }
          }

          // for (var i = 0; i < data.data.length; i++) {
          // 	if(data.data[i+1]&&data.data[i].type==data.data[i+1].type){
          // 		console.log(data.data[i].type,data.data[i].name);
          // 		if(data.data[i].type==data.data[i].name){
          // 			data.data[i].children.push(data.data[i+1])
          // 			data.data.splice(i+1,1)
          // 		}else{
          // 			data.data[i+1].children= []
          // 			// console.log(data.data[i+1],'before');

          // 			data.data[i+1].children.push(data.data[i])
          // 			// console.log(data.data[i+1],'after');
          // 			data.data.splice(i,1)

          // 		}
          // 	}

          // }
          let tree = treeDataTranslate(data.data);
          this.calleArr(tree);
          tree.push(this.prescription);
          console.log(tree, "tree");

          // const arr1= [{
          // 	name:'全周期问卷分类',
          // 	id:'303868',
          // 	children:[]
          // }]
          // arr1[0].children=tree
          // console.log(arr1,111);
          this.setTree = tree;
          console.log("initExpandinitExpandinitExpand");
          console.log(this.setTree);
          // this.defaultExpandKeys.push(this.setTree[0].id);
          if (data.data.length > 0) {
            this.maxexpandId = data.data[data.data.length - 1].id;
            this.non_maxexpandId = data.data[data.data.length - 1].id;
            this.num = data.data[data.data.length - 1].num;
          }
        });
      }
    },
    setChecked(item) {
      console.log(item);
      this.$refs.expandMenuList.setChecked(item.id, false);
    },
    setChecked2(item) {
      console.log(item);
      this.$refs.expandMenuList.setChecked(item.id, true);
    },
    calleArr(array) {
      for (let i in array) {
        var data = array[i];
        console.log("calleArr", data.children);
        if (data.children) {
          if (data.children.length > 0) {
            this.calleArr(data.children); //自己调用自己
          } else {
            delete data.children;
          }
        }
      }
    },
    handleNodeClick(
      checkedNodes,
      checkedKeys,
      halfCheckedNodes,
      halfCheckedKeys
    ) {
      //点击节点

      this.$emit(
        "refreshDataList",
        checkedNodes,
        checkedKeys,
        halfCheckedNodes,
        halfCheckedKeys
      );
    },
    renderContent(h, { node, data, store }) {
      //加载节点
      let that = this;
      return h(TreeRender, {
        props: {
          DATA: data,
          NODE: node,
          STORE: store,
          maxexpandId: that.non_maxexpandId,
          currentId: this.currentId || 1,
        },
        on: {
          nodeAdd: (s, d, n) => that.handleAdd(s, d, n),
          nodeEdit: (s, d, n) => that.handleEdit(s, d, n),
          nodeDel: (s, d, n) => that.handleDelete(s, d, n),
        },
      });
    },

    handleAdd(s, d, n) {
      //增加节点
      console.log("1");
      if (n.level >= 2) {
        this.$message.error("最多只支持二级！");
        return false;
      }
      //添加数据
      // let pidStr = ''
      // if(d.pids==''){
      // 	pidStr=d.id
      // }else
      // {
      // 	pidStr=d.pids + ',' + d.id
      // }
      let user = JSON.stringify(localStorage.getItem("userInfo"));
      d.children.push({
        id: "",
        num: ++this.num,
        simpleName: "二级分类",
        pid: d.id,
        pids: d.id,
        isEdit: false,
        children: [],
      });
      //展开节点
      if (!n.expanded) {
        n.expanded = true;
      }
      let Dept = {
        // 'userId':this.$cookie.get('userId'),
        // 'num': this.num,
        // 'pid': d.id,
        // pids: d.id ,
        // "simpleName": "二级部门",
        // "fullName": "二级部门",
        // "orderWeight": 0,
        // 'status':1,
        // "version": '',
        type: "全周期问卷分类",
        name: "二级分类",
        createName: user.name,
        classify: "联盟",
      };
      this.$http({
        url: this.$http.adornUrl("/dict/insert"),
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
        data: Qs.stringify(Dept),
      }).then(({ data }) => {
        if (data.status) {
          this.getDeptList();
        }
      });
    },
    handleEdit(s, d, n) {
      //编辑节点
      console.log("触发编辑", s, n);
      d.simpleName = d.fullName;
      console.log(d);
      let obj = {
        id: d.id,
        // 'pid':d.pid,
        // 'pids':d.pids,
        name: d.name,
        // 'simpleName':d.simpleName
      };
      // let dept = d
      console.log(obj, "obj");
      this.$http({
        url: this.$http.adornUrl("/dict/update"),
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          // 'Content-Type':' application/json;charset=UTF-8',
          token: this.$cookie.get("token"),
        },
        data: Qs.stringify(obj),
      }).then(({ data }) => {
        // console.log(data,'dataaaaa');
        if (data.status) {
          // this.$message({
          // 	message: '123',
          // 	type: "success",
          // 	duration: 1500,
          // 	onClose: () => {

          // 		},
          // });
          this.$message.success(data.msg);
          this.getDeptList();
        }
      });
    },
    handleDelete(s, d, n) {
      //删除节点
      console.log(d);
      let that = this;
      let _menu = d.children;
      let menuId = [d.id];
      let len = _menu.length;
      for (let i = 0; i < len; i++) {
        let item = _menu[i];
        if (item.children && item.children.length != 0) {
          let children = item.children;
          for (let j = 0; j < children.length; j++) {
            _menu[len + j] = children[j];
          }
          len = _menu.length;
        }
        menuId.push(item.id);
      }
      let ids = {
        userId: this.$cookie.get("userId"),
        ids: menuId.join(","),
      };
      this.$http({
        url: this.$http.adornUrl("/dept/chooseQs2"),
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: this.$cookie.get("token"),
        },
        data: Qs.stringify(ids),
      }).then(({ data }) => {
        if (data.status) {
          this.$message.success("删除成功！");
          this.getDeptList();
        }
      });
      //有子级不删除
      //         if(d.children && d.children.length !== 0){
      //           this.$message.error("此节点有子级，不可删除！")
      //           return false;
      //         }else{
      //           //新增节点直接删除，否则要询问是否删除
      //           let delNode = () => {

      //             let list = n.parent.data.children || n.parent.data,//节点同级数据
      //               _index = 99999;//要删除的index
      //             /*if(!n.parent.data.children){//删除顶级节点，无children
      //               list = n.parent.data
      //             }*/
      //             list.map((c,i) => {
      //               if(d.id == c.id){
      //                 _index = i;
      //               }
      //             })
      //             let k = list.splice(_index,1);
      //             //console.log(_index,k)
      //             this.$message.success("删除成功！")
      //           }
      //           let isDel = () => {
      //             that.$confirm("是否删除此节点？","提示",{
      //               confirmButtonText: "确认",
      //               cancelButtonText: "取消",
      //               type: "warning"
      //             }).then(() => {
      //               delNode()
      //             }).catch(() => {
      //               return false;
      //             })
      //           }
      //           //判断是否新增
      //           d.id > this.non_maxexpandId ? delNode() : isDel()
      //
      //         }
    },
  },
  watch: {
    type: function (value) {
      // console.log(value,'value watch');
      this.type = value;
    },
    content: function (value) {
      console.log(value, "value watch");
      this.content = value;
      this.getDeptList();
    },
  },
};
</script>

<style>
.expand {
  width: 100% !important;
  overflow: hidden;
}
.is-leaf:before {
  content: "" !important;
}
.expand > div {
  height: 100%;

  /* 	border: 1px solid #cccccc; */

  overflow-y: auto;
}

.expand > div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.expand > div::-webkit-scrollbar-thumb {
  background-color: rgba(50, 65, 87, 0.5);
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.expand > div::-webkit-scrollbar {
  width: 10px;
}

.expand-tree {
  border: none;
  margin-top: 10px;
}

.expand-tree .el-tree-node.is-current,
.expand-tree .el-tree-node:hover {
  overflow: hidden;
}

.el-tree-node__content {
  height: 28px;
}

.expand-tree .is-current > .el-tree-node__content .tree-btn,
.expand-tree .el-tree-node__content:hover .tree-btn {
  display: inline-block;
}

.expand-tree .is-current > .el-tree-node__content .tree-label {
  white-space: normal;
}
</style>
