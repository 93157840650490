<template>
  <div class="agreement-form">
    <el-dialog
      title="保存协定方"
      :visible.sync="visible"
      width="1200"
      :close-on-click-modal="false"
    >
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        inline
        class="custom-form"
      >
        <el-form-item prop="agreementRecordsName" label="协定方名称">
          <el-input
            v-model="dataForm.agreementRecordsName"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="type" label="开放范围">
          <el-select v-model="dataForm.type">
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :value="item.value"
              :label="item.name"
              >{{ item.name }}</el-option
            >
          </el-select>
        </el-form-item>
      </el-form>
      <div class="con-title">医嘱内容</div>
      <el-table :data="addRecipelList">
        <el-table-column label="药品名称">
          <template slot-scope="scope">
            <span v-if="scope.row.drugInfo && scope.row.drugInfo.id">{{
              scope.row.drugInfo.drugsFullName
            }}</span>
            <el-select
              placeholder="输入药品名称/拼音搜索"
              v-model="scope.row.drugInfo"
              style="width: 100%"
              filterable=""
              value-key="id"
              :filter-method="filterValue"
              @change="(val) => changeEditDrugInfo(scope.row, val, item)"
              v-else
            >
              <el-option
                v-for="(item, index) in drugList"
                :key="item.id"
                :value="item"
                :label="item.drugsFullName"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="剂量" width="150">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.drugInfo && scope.row.drugInfo.id"
              v-model="scope.row.drugsDose"
            ></el-input>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="单位" width="150">
          <template slot-scope="scope">
            <el-select
              placeholder="请选择"
              v-model="scope.row.drugsUseUnit"
              v-if="scope.row.drugInfo && scope.row.drugInfo.id"
            >
              <el-option
                v-for="(item, index) in unitList"
                :key="index"
                :value="item.name"
                :label="item.name"
              ></el-option>
            </el-select>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="特殊要求">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.drugInfo && scope.row.drugInfo.id"
              v-model="scope.row.medicationRequirements"
              placeholder="请输入"
            ></el-input>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="right"
          header-align="right"
          width="120"
        >
          <template slot-scope="scope">
            <div
              class="delete-btn"
              v-if="scope.row.drugInfo && scope.row.drugInfo.id"
              @click="deleteDrugInfo(scope.row, scope.$index)"
            >
              <img src="@/assets/img/case/delete.png" />
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
      <el-form class="recipel-form" inline>
        <div class="recipel-row">
          <el-form-item label="服药频次">
            <el-select v-model="recipelForm.frequency" style="width: 100%">
              <el-option
                v-for="(n, index) in frequencyList"
                :key="index"
                :value="n.name"
                :label="n.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单剂次数">
            <el-select v-model="recipelForm.singleDoses" style="width: 100%"
              ><el-option
                v-for="(n, index) in singleDosesList"
                :key="index"
                :value="n.name"
                :label="n.name"
              ></el-option
            ></el-select>
          </el-form-item>
          <el-form-item label="总量">
            <el-input v-model="recipelForm.total" style="width: 100%">
              <span slot="suffix">付</span>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item label="服药要求" style="width: 100%">
          <el-input
            type="textarea"
            v-model="recipelForm.medicationRequirements"
            rows="5"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addPrescriptionRecipel"
          >保存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        agreementRecordsName: "",
        type: 1,
      },
      dataRule: {
        agreementRecordsName: [
          { required: true, message: "请输入协议方名称", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择开放范围", trigger: "change" },
        ],
      },
      typeList: [
        { name: "团队内共享", value: 1 },
        { name: "仅机构内共享", value: 2 },
        { name: "仅自己使用", value: 3 },
      ],
      addRecipelList: [
        {
          drugInfo: {},
          drugsDose: "",
          drugsUseUnit: "",
          medicationRequirements: "",
        },
      ], //要添加的药品信息
      recipelForm: {
        frequency: "", //频次
        singleDoses: "", //单次剂数
        total: "", //总量
        medicationRequirements: "", //服药要求
        prescriptionType: 1, //中药
      }, //添加药品信息
      drugList: [], //搜索的中药列表
      unitList: [], //用药单位
      frequencyList: [], //频次列表
      singleDosesList: [], //单剂次数
      loading: false,
      prescriptionRecordInfo: {},
      type: "",//区分是不是编辑协定方
      deleteDrugList: []
    };
  },
  methods: {
    init(item, type) {
      this.visible = true;
      this.type = type;
      console.log(item);
      this.getDrugList(1);
      this.getRecipelUnit("单位");
      this.getRecipelUnit("单剂次数");
      this.getRecipelUnit("用药频次");
      if (type) {
        if (item && item.id) {
          this.addRecipelList = [];
          this.prescriptionRecordInfo = item;
          this.recipelForm.frequency = item.frequency;
          this.recipelForm.singleDoses = item.singleDoses;
          this.recipelForm.total = item.total;
          this.recipelForm.medicationRequirements = item.medicationRequirements;
          this.dataForm.agreementRecordsName = item.agreementRecordsName;
          this.dataForm.type = item.type;
          this.dataForm.id = item.id;
          if (item.agreementDrugs && item.agreementDrugs.length) {
            item.agreementDrugs.forEach((ele) => {
              let obj = {
                drugInfo: {
                  id: ele.drugsId,
                  drugsFullName: ele.drugsName,
                },
                id: ele.id,
                drugsDose: ele.drugsDose,
                drugsId: ele.drugsId,
                drugsName: ele.drugsName,
                drugsUseUnit: ele.drugsUseUnit,
                prescriptionRecordsId: ele.prescriptionRecordsId,
                medicationRequirements: ele.medicationRequirements,
              };
              this.addRecipelList.push(obj);
            });
          }
          this.addRecipelList.push({
            drugInfo: {},
            drugsDose: "",
            drugsUseUnit: "",
            medicationRequirements: "",
          });
          console.log(this.addRecipelList);
        }
      } else {
        if (item && item.id) {
          this.addRecipelList = [];
          this.prescriptionRecordInfo = item;
          this.recipelForm.frequency = item.frequency;
          this.recipelForm.singleDoses = item.singleDoses;
          this.recipelForm.total = item.total;
          this.recipelForm.medicationRequirements = item.medicationRequirements;
          if (
            item.mbglPrescriptionDrugsList &&
            item.mbglPrescriptionDrugsList.length
          ) {
            item.mbglPrescriptionDrugsList.forEach((ele) => {
              let obj = {
                drugInfo: {
                  id: ele.drugsId,
                  drugsFullName: ele.drugsName,
                },
                id: ele.id,
                drugsDose: ele.drugsDose,
                drugsId: ele.drugsId,
                drugsName: ele.drugsName,
                drugsUseUnit: ele.drugsUseUnit,
                prescriptionRecordsId: ele.prescriptionRecordsId,
                medicationRequirements: ele.medicationRequirements,
              };
              this.addRecipelList.push(obj);
            });
          }
          this.addRecipelList.push({
            drugInfo: {},
            drugsDose: "",
            drugsUseUnit: "",
            medicationRequirements: "",
          });
          console.log(this.addRecipelList);
        }
      }
    },
    // 获取药品列表
    getDrugList(type) {
      let params = {
        stringParam1: "",
        intParam1: 10000,
        intParam2: 1,
        intParam3: type,
      };
      this.loading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/drugsManage/list"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.drugList = data.data;
          this.loading = false;
        }
      });
    },
    // 获取用药单位
    getRecipelUnit(typeName) {
      this.$httpAes({

        url: this.$httpAes.adornUrl("/dict/list?type=" + typeName),
        method: "post",
		data: {
		  stringParam1: typeName,
		
		},
      }).then(({ data }) => {
        if (typeName == "单位") {
          this.unitList = data.data;
        }
        if (typeName == "单剂次数") {
          this.singleDosesList = data.data;
        }
        if (typeName == "用药频次") {
          this.frequencyList = data.data;
        }
        if (typeName == "处方用法") {
          this.drugsUseTypeList = data.data;
        }
        if (typeName == "天数") {
          this.dayList = data.data;
        }
      });
    },
    //根据拼音搜索列表
    filterValue(value) {
      console.log(value);
      let params = {
        stringParam1: value,
    
        intParam1: 10000,
        intParam2: 1,
        intParam3: 1,
      };
      // if (type == 2) {
      //   params.hospitalId = currentTeam.hospitalId;
      //   params.deptId = currentTeam.hospitalId;
      // }
      this.$httpAes({
        url: this.$httpAes.adornUrl("/drugsManage/list"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.drugList = data.data;
          // this.getSinglePrescriptionInfo();
        }
      });
    },

    // 编辑状态改变药品名称
    changeEditDrugInfo(row, value) {
      let num = 0;
      row = JSON.parse(JSON.stringify(row));
      if (row.drugInfo && row.drugInfo.id) {
        row.drugsDose = value.drugsBaseDose;
        row.drugsUseUnit = value.drugsUseUnitName ? value.drugsUseUnitName : "";
      }
      this.addRecipelList.forEach((ele) => {
        if (ele.drugInfo && ele.drugInfo.id) {
          num++;
        }
      });
      if (num == this.addRecipelList.length) {
        this.addRecipelList.push({
          drugInfo: {},
          drugsId: "",
          drugsName: "",
          drugsDose: "",
          drugsUseUnit: "",
          medicationRequirements: "",
        });
      }
      this.$forceUpdate();
    },

    // 新增处方中的药品信息
    addPrescriptionRecipel() {
      let _this = this;
      _this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let arr = [],
            isNull = "";
          if (this.addRecipelList.length > 1) {
            this.addRecipelList.forEach((ele) => {
              if (ele.drugInfo && ele.drugInfo.id) {
                if (!(ele.drugsDose && ele.drugsUseUnit)) {
                  isNull++;
                  return;
                }
                let obj = {
                  drugsId: ele.drugInfo.id,
                  drugsName: ele.drugInfo.drugsFullName,
                  drugsDose: ele.drugsDose,
                  drugsUseUnit: ele.drugsUseUnit,
                  medicationRequirements: ele.medicationRequirements,
                  status: 1,
                };
                if (this.dataForm.id) {
                  obj.id = ele.id;
                }
                arr.push(obj);
              }
            });
          } else {
            isNull++;
          }
          if (isNull) {
            this.$message.error("请完善药品信息");
            return;
          }
          if (this.deleteDrugList.length) {
            this.deleteDrugList.forEach((ele)=>{
              ele.status = 2;
            })
          }
          arr = [...arr, ...this.deleteDrugList];
          let params = {
            agreementDrugs: arr,
            teamsId: this.prescriptionRecordInfo.teamsId,
            patientId: this.prescriptionRecordInfo.patientId,
            visitUuid: this.prescriptionRecordInfo.visitUuid,
            status: 1,
          };
          params = Object.assign({}, params, this.recipelForm, this.dataForm);
          console.log(params);
          // return;
          this.$http({
            url: this.$http.adornUrl("/agreementRecords/add"),
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            data: params,
          }).then(({ data }) => {
            if (data.body.status) {
              this.$message({
                message: "创建成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  if (this.type) {
                    this.$emit("refreshData");
                  }
                },
              });
            } else {
              this.$message({
                message: data.body.msg,
                type: "error",
                duration: 1500,
                onClose: () => {},
              });
            }
          });
        }
      });
    },
    // 删除中药填写的药品信息
    deleteDrugInfo(row, index) {
      // if (row.id) {
      //   this.$confirm(`确定进行删除操作?`, "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonClass: "btn-custom-cancel",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }).then(() => {
      //     this.addRecipelList.splice(index, 1);
      //   });
      // }
      console.log(row)
      if(row.id) {
        this.deleteDrugList.push(row);
      }
      this.addRecipelList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement-form {
  /deep/.el-dialog {
    width: 1200px !important;
  }
  .custom-form {
    display: flex;
    .el-form-item {
      flex: 1;
      /deep/.el-input__inner {
        width: 350px !important;
      }
    }
  }

  .con-title {
    padding-left: 14px;
    padding-bottom: 16px;
    color: #666666;
    &::before {
      content: "*";
      color: #f41717;
    }
  }
  .delete-btn {
    img {
      width: 18px;
      height: 18px;
    }
  }
  .recipel-form {
    padding-top: 24px;
    .el-form-item {
      width: 100%;
      /deep/.el-input__inner {
        width: 240px !important;
      }
      /deep/.el-textarea__inner {
        width: 1049px !important;
      }
    }
    .recipel-row {
      display: flex;
      justify-content: space-between;
      .el-form-item {
        width: 100%;
      }
    }
  }
}
</style>
